<template>
  <div>
    <!--하운슬로 파워볼-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M
                   || kind == managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M" label="하운슬로 파워볼 3분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M" label="하운슬로 파워볼 5분"></el-option>
    </el-select>
    <!--엔트리 EOS 파워볼-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M
                   || kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M
                   || kind == managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M
                   || kind == managerConst.LeisureGame.LEISURE_KIND_POWERSADALI
                   || kind == managerConst.LeisureGame.LEISURE_KIND_KINOSADALI
                    || kind == managerConst.LeisureGame.LEISURE_KIND_SPEEDKINO">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M" label="EOS 파워볼 1분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M" label="EOS 파워볼 3분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M" label="EOS 파워볼 5분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_POWERSADALI" label="엔트리 파워사다리"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SPEEDKINO" label="엔트리 스피드 키노"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_KINOSADALI" label="엔트리 키노 사다리"></el-option>
    </el-select>

    <!--Bet365 가상축구-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP"
                 label="가상축구 - 프리미어"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP" label="가상축구 - 유로컵"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE"
                 label="가상축구 - 슈퍼리그"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP" label="가상축구 - 월드컵"></el-option>
    </el-select>

    <!--Bet365 가상농구-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER"
                 label="가상농구 - 워터포드"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER" label="가상농구 - 베이커"></el-option>
    </el-select>

    <!--Bet365 가상개경주-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK"
                 label="가상개경주 - 골든힐"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK"
                 label="가상개경주 - 힐사이드"></el-option>
    </el-select>

    <!--Bet365 가상경마-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2" label="가상경마 - 브리타니아"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3" label="가상경마 - 페스티발"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4" label="가상경마 - 픽토리아"></el-option>
    </el-select>

    <!--별다리-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1" label="보스코어 별다리 1분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2" label="보스코어 별다리 2분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3" label="보스코어 별다리 3분"></el-option>
    </el-select>

    <!--크라운-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_SUTDA
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_NINEBALL">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SUTDA" label="크라운 섯다"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NINEBALL" label="크라운 나인볼"></el-option>
    </el-select>
    <!--스카이파크-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_SKY_DICE">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA" label="스카이파크 바카라"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN" label="스카이파크 홀짝"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SKY_DICE" label="스카이파크 주사위"></el-option>
    </el-select>
    <!--로투스-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1" label="로투스 바카라"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS" label="로투스 홀짝"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER" label="로투스 드래곤타이거"></el-option>
    </el-select>

    <!--JW Casino-->
    <!--        <el-select size="mini" style="width: 180px;margin-right: 5px"-->
    <!--                   v-model="kind" placeholder=""-->
    <!--                   @change="changeLeisureKind"-->
    <!--                   v-if="kind == managerConst.LeisureGame.LEISURE_KIND_JWCASINO_ANGELDEMONS-->
    <!--                    ||kind == managerConst.LeisureGame.LEISURE_KIND_JWCASINO_SUPERMARIO-->
    <!--                    ||kind == managerConst.LeisureGame.LEISURE_KIND_JWCASINO_OSTRICHRUN">-->
    <!--            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_JWCASINO_ANGELDEMONS" label="천사악마"></el-option>-->
    <!--            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_JWCASINO_OSTRICHRUN" label="타조게임"></el-option>-->
    <!--            <el-option :value="managerConst.LeisureGame.LEISURE_KIND_JWCASINO_SUPERMARIO" label="슈퍼마리오"></el-option>-->
    <!--        </el-select>-->

    <!--게임모아-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_1M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_3M">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_1M" label="보글사다리 1분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_3M" label="보글사다리 3분"></el-option>
    </el-select>
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_1M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_5M">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_1M" label="별다리 1분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_5M" label="별다리 5분"></el-option>
    </el-select>
    <!--네임드-->
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_3S
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_5S
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_3S
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_5S">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_3S" label="네임드 뉴 파워볼 3분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_5S" label="네임드 뉴 파워볼 5분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_3S"
                 label="네임드 뉴 파워사다리 3분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_5S"
                 label="네임드 뉴 파워사다리 5분"></el-option>
    </el-select>

    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_NEXT_BACCARA
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NEXT_SADALI
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NEXT_POWERBALL
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_NEXT_RING1M">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NEXT_SADALI" label="NEXT 사다리"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NEXT_BACCARA" label="NEXT 바카라"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NEXT_POWERBALL" label="NEXT 파워볼"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_NEXT_RING1M" label="NEXT 1분링"></el-option>
    </el-select>
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_1M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_2M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_3M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_1M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_2M
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_3M">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_1M" label="슈어맨 파워볼 1분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_2M" label="슈어맨 파워볼 2분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_3M" label="슈어맨 파워볼 3분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_1M" label="슈어맨 사다리 1분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_2M" label="슈어맨 사다리 2분"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_3M" label="슈어맨 사다리 3분"></el-option>
    </el-select>


    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_PAMGAME_MARIO
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_PAMGAME_SLOT
                    ||kind == managerConst.LeisureGame.LEISURE_KIND_PAMGAME_DRAGONTIGER">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_PAMGAME_MARIO" label="마리오"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_PAMGAME_SLOT" label="슬롯"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_PAMGAME_DRAGONTIGER" label="드래곤타이거"></el-option>
    </el-select>
    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_MGM_BAKARA
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_MGM_ODDEVEN">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_MGM_BAKARA" label="MGM 바카라"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_MGM_ODDEVEN" label="MGM 홀짝"></el-option>
    </el-select>

    <el-select size="mini" style="width: 180px;margin-right: 5px"
               v-model="kind" placeholder=""
               @change="changeLeisureKind"
               v-if="kind == managerConst.LeisureGame.LEISURE_KIND_DONGHANG_POWERBALL_RANDOM
                   ||kind == managerConst.LeisureGame.LEISURE_KIND_DONGHANG_POWERSADALI">
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_DONGHANG_POWERBALL_RANDOM" label="동행파워볼(랜덤)"></el-option>
      <el-option :value="managerConst.LeisureGame.LEISURE_KIND_DONGHANG_POWERSADALI" label="동행파워사다리"></el-option>
    </el-select>
  </div>

</template>

<script>
import managerConst from "../../common/administrator/managerConst";

export default {
  name: "LeisuregameSelectorComp",
  data() {
    return {
      selectedStatus: 'open',
      kind: 0,
      managerConst
    }
  },
  props: {
    pkind: {
      type: Number,
      default() {
        return 0;
      }
    },

  },
  methods: {
    changeLeisureKind() {
      this.$emit('changeLeisureKind', this.kind)
    }
  },
  created() {
    this.kind = this.pkind
  },
  watch: {
    pkind(newVal) {
      this.kind = newVal
    }
  }
}
</script>

<style scoped>

</style>